<template>
  <div>
    <BCard title="벤더 정보">
      <BRow>
        <BCol cols="4">
          <BFormGroup label="벤더 IDX">
            <TagbyLinkInput
              v-if="vendorObj != null"
              :value="vendorObj.idx"
              :to="{name: 'member-adv-view', params: {idx: vendorObj.idx}}"
            />
            <BFormInput
              v-else
              readonly
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="벤더 회사명">
            <BFormInput
              :value="vendorObj != null ? vendorObj.company_name : null"
              readonly
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="벤더명">
            <BFormInput
              :value="vendorObj != null ? vendorObj.name : null"
              readonly
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import useVendorInfo from '../../useVendorInfo'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbyLinkInput,
  },
  setup() {
    return {
      ...useVendorInfo(),
    }
  },
})
</script>
