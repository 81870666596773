<template>
  <div>
    <BCard title="가격 정보">
      <BRow>
        <BCol cols="4">
          <BFormGroup label="공급가">
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                v-model="supplyPrice"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="가격">
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                v-model="price"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="권장소비자가">
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                v-model="marketPrice"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="태그바이수수료율">
            <BInputGroup
              append="%"
              class="input-group-merge"
            >
              <TagbyFeeInput
                v-model="tagbyFeeRate"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="태그바이수수료">
            <BInputGroup
              id="product_tagby_fee"
              append="원"
              class="input-group-merge"
            >
              <BTooltip
                target="product_tagby_fee"
                triggers="hover"
                variant="info"
              >
                (가격 + 배송비) * (태그바이수수료율 + 0.03)
              </BTooltip>
              <BFormInput
                :value="computedTagbyFee"
                readonly
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="셀러수수료">
            <BInputGroup
              id="product_inf_fee"
              append="원"
              class="input-group-merge"
            >
              <BTooltip
                target="product_inf_fee"
                triggers="hover"
                variant="info"
              >
                (가격 - 공급가 - 태그바이수수료) * 10/11
              </BTooltip>
              <BFormInput
                :value="computedInfFee"
                readonly
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="영업외 수익">
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                :value="computedEtcIncome"
                readonly
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="면세상품">
            <BFormCheckbox
              v-model="isTaxFree"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BTooltip,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import TagbyFeeInput from '@/components/TagbyFeeInput.vue'
import TagbyRangeInput from '@/components/TagbyRangeInput.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import usePriceInfo from '../../usePriceInfo'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BTooltip,

    TagbyCopyableInput,
    TagbyLinkInput,
    TagbyFeeInput,
    TagbyRangeInput,
    TagbyDatetimeInputV2,
  },
  setup() {
    return {
      ...usePriceInfo(),
    }
  },
})
</script>
