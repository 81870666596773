<template>
  <div>
    <BCard title="상품 정보">
      <BRow>
        <BCol cols="4" />
        <BCol cols="4">
          <BFormGroup label="상품명">
            <BFormInput
              v-model="name"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="브랜드명">
            <BFormInput
              v-model="brandName"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="카테고리">
            <TagbySelect
              v-model="categoryIdx"
              :options="categoryOptions"
              :reduce="option => option.value"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="제품번호">
            <TagbyCopyableInput
              v-model="productNo"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="재고">
            <BInputGroup
              v-if="hasOptions"
              id="product_stock"
              append="개"
              class="input-group-merge"
            >
              <BTooltip
                target="product_stock"
                triggers="hover"
                variant="info"
              >
                옵션 재고들의 합
              </BTooltip>
              <BFormInput
                :value="computedOptionStock"
                readonly
              />
            </BInputGroup>
            <BInputGroup
              v-else
              append="개"
              class="input-group-merge"
            >
              <BFormInput
                v-model="stock"
                number
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="판매 타입">
            <TagbySelect
              v-model="sellType"
              :options="sellTypeOptions"
              :reduce="option => option.value"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="판매 상태">
            <TagbySelect
              v-model="state"
              :options="stateOptions"
              :reduce="option => option.value"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="출시일">
            <TagbyDatetimeInputV2
              v-model="startAt"
              :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="판매종료일">
            <TagbyDatetimeInputV2
              v-model="endAt"
              :config="{enableTime: true, dateFormat: 'Y-m-d H:i'}"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="썸네일">
            <TagbyImgManger
              ref="thumbImgRef"
              :imgObj="thumbImgFile"
              :isSaving="isSavingImgFile"
              @ok="updateThumbImg"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="상세설명">
            <TagbyEditor v-model="detailHtmlContent" />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="상품정보">
            <BFormTextarea
              v-model="description"
              rows="5"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BImg,
  BTooltip,
  BFormTextarea,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import TagbyPopoverImg from '@/components/TagbyPopoverImg.vue'
import TagbyEditor from '@/components/TagbyEditor.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyImgManger from '@/components/TagbyImgManger.vue'
import useProductInfo from '../../useProductInfo'
import useThumbImg from '../../useThumbImg'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BImg,
    BTooltip,
    BFormTextarea,

    TagbyCopyableInput,
    TagbyLinkInput,
    TagbyDatetimeInputV2,
    TagbyPopoverImg,
    TagbyEditor,
    TagbySelect,
    TagbyImgManger,
  },
  setup() {
    return {
      ...useProductInfo(),
      ...useThumbImg(),
    }
  },
})
</script>
