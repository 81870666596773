<template>
  <TagbyActionButton
    variant="outline-primary"
    text="생성"
    :isSaving="isSaving"
    @click="createProduct"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import useCreateProduct from '../../useCreateProduct'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    return {
      ...useCreateProduct(),
    }
  },
})
</script>
