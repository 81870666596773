<template>
  <TagbyDetailLayout>
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(product)>
          <ProductInfo />
        </template>

        <template #tab(price)>
          <PriceInfo />
        </template>

        <template #tab(delivery)>
          <ShippingInfo />
        </template>

        <template #tab(option)>
          <OptionInput />
          <OptionInfo />
        </template>

        <template #tab(etc)>
          <AbroadInfo />
          <VendorInfo />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
    </template>

    <template #hidden>
      <Hiddens />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import useProductInfo from './useProductInfo'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import TagbyTabs from '@/components/TagbyTabs.vue'
import ActionCard from './blocks/ActionCard'
import ProductInfo from './blocks/ProductInfo'
import PriceInfo from './blocks/PriceInfo'
import ShippingInfo from './blocks/ShippingInfo'
import OptionInput from './blocks/OptionInput'
import OptionInfo from './blocks/OptionInfo'
import AbroadInfo from './blocks/AbroadInfo'
import VendorInfo from './blocks/VendorInfo'
import Hiddens from './blocks/Hiddens'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    ActionCard,
    ProductInfo,
    PriceInfo,
    ShippingInfo,
    OptionInput,
    OptionInfo,
    AbroadInfo,
    VendorInfo,
    Hiddens,
  },
  setup() {
    const tabList = [
      { key: 'product', title: '상품' },
      { key: 'price', title: '가격' },
      { key: 'delivery', title: '배송' },
      { key: 'option', title: '옵션' },
      { key: 'etc', title: '기타' },
    ]
    const {
      fetchCategories,
    } = useProductInfo()
    fetchCategories()

    return {
      tabList,
    }
  },
})
</script>
