<template>
  <div>
    <BCard title="배송 정보">
      <BRow>
        <BCol cols="4">
          <BFormGroup label="배송비">
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                v-model="shippingFee"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="무료배송비조건">
            <BInputGroup
              append="원 이상"
              class="input-group-merge"
            >
              <BFormInput
                v-model="shippingFreeCond.gte"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="최대묶음개수">
            <BInputGroup
              append="개"
              class="input-group-merge"
            >
              <BFormInput
                v-model="maxBundleCount"
                number
                type="number"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="합배송그룹">
            <BFormInput
              :value="shippingPolicyObj != null ? shippingPolicyObj.name : null"
              readonly
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="제주추가배송비">
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                v-model="additionalShippingFeeCond.jeju"
                number
                type="number"
                :readonly="shippingPolicyObj != null"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="도서산간추가배송비">
            <BInputGroup
              append="원"
              class="input-group-merge"
            >
              <BFormInput
                v-model="additionalShippingFeeCond.island_mountain"
                number
                type="number"
                :readonly="shippingPolicyObj != null"
              />
            </BInputGroup>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="배송일">
            <TagbyRangeInputV2
              v-model="deliveryDays"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="배송상세정보">
            <BFormTextarea
              v-model="deliveryContent"
              rows="5"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="환불상세정보">
            <BFormTextarea
              v-model="refundContent"
              rows="5"
            />
          </BFormGroup>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import TagbyFeeInput from '@/components/TagbyFeeInput.vue'
import TagbyRangeInputV2 from '@/components/TagbyRangeInputV2.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import useProductInfo from '../../useProductInfo'
import useShippingPolicy from '../../useShippingPolicy'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,

    TagbyCopyableInput,
    TagbyLinkInput,
    TagbyFeeInput,
    TagbyRangeInputV2,
    TagbyDatetimeInputV2,
  },
  setup() {
    return {
      ...useProductInfo(),
      ...useShippingPolicy(),
    }
  },
})
</script>
