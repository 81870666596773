import data from './data'

export default () => {
  const updateThumbImg = imgObj => {
    data.thumbImg.isSavingImgFile.value = true
    data.thumbImg.thumbImgFile.value = imgObj
    data.thumbImg.isSavingImgFile.value = false
    data.thumbImg.thumbImgRef.value.turnOffSidebar()
  }

  return {
    ...data.thumbImg,
    updateThumbImg,
  }
}
