<template>
  <BModal
    :visible="visible"
    cancel-variant="outline-secondary"
    ok-variant="primary"
    :ok-title="okTitle"
    :cancel-title="cancelTitle"
    centered
    :title="title"
    :ok-only="okOnly"
    @ok="$emit('ok')"
    @hidden="$emit('hidden')"
  >
    <slot />
    <slot name="body">
      {{ content }}
    </slot>
  </BModal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    okTitle: {
      type: String,
      default: '확인',
    },
    cancelTitle: {
      type: String,
      default: '취소',
    },
    okOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'ok',
    'hidden',
  ],
}
</script>
