<template>
  <TagbySidebarWithButton
    title="벤더 변경"
    :visible="visibleChangeVendorSidebar"
    :isSaving="isSavingChangeVendor"
    :isValid="isValidChangeVendor"
    @ok="changeVendor"
    @hidden="turnOffChangeVendorSidebar"
    @cancel="turnOffChangeVendorSidebar"
  >
    <BRow>
      <BCol>
        <BFormGroup>
          <BInputGroup>
            <BFormInput
              v-model="searchText"
              @keydown.enter="fetchVendorList"
            />
            <BInputGroupAppend>
              <BButton
                variant="outline-primary"
                @click="fetchVendorList"
              >
                검색
              </BButton>
            </BInputGroupAppend>
          </BInputGroup>
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BTable
        ref="vendorTableRef"
        :fields="vendorFields"
        :items="vendorList"
        :busy="isFetchingVendorList"
        small
        show-empty
        selectable
        select-mode="single"
      />
    </BRow>
  </TagbySidebarWithButton>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import useVendorInfo from '../../useVendorInfo'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  setup() {
    return {
      ...useVendorInfo(),
    }
  },
})
</script>
