<template>
  <div v-if="optionType !== 'NO'">
    <BCard title="옵션 정보">
      <BButton
        variant="outline-danger"
        class="mb-1"
        @click="turnOnDeleteOptionModal"
      >
        선택 삭제
      </BButton>
      <BTable
        ref="optionsTableRef"
        :fields="optionFields"
        :items="activeOptions"
        small
        selectable
        selected-variant=""
      >
        <template #cell(selected)="row">
          <BFormCheckbox
            v-model="row.rowSelected"
            class="vs-checkbox-con"
            @change="toggleTableChecked(row)"
          />
        </template>

        <template #cell(add_supply_price)="data">
          <BInputGroup
            append="원"
            class="input-group-merge"
          >
            <BFormInput
              :value="data.item.add_supply_price"
              type="number"
              number
              @input="inputOption(data.item.idx, 'add_supply_price', $event)"
            />
          </BInputGroup>
        </template>

        <template #cell(add_price)="data">
          <BInputGroup
            append="원"
            class="input-group-merge"
          >
            <BFormInput
              :value="data.item.add_price"
              type="number"
              number
              @input="inputOption(data.item.idx, 'add_price', $event)"
            />
          </BInputGroup>
        </template>

        <template #cell(add_tagby_fee)="data">
          <BInputGroup
            append="원"
            class="input-group-merge"
          >
            <BFormInput
              :value="data.item.add_tagby_fee"
              type="number"
              number
              @input="inputOption(data.item.idx, 'add_tagby_fee', $event)"
            />
          </BInputGroup>
        </template>

        <template #cell(stock)="data">
          <BInputGroup
            append="개"
            class="input-group-merge"
          >
            <BFormInput
              :value="data.item.stock"
              type="number"
              number
              @input="inputOption(data.item.idx, 'stock', $event)"
            />
          </BInputGroup>
        </template>
      </BTable>
    </BCard>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BInputGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  defineComponent,
} from '@vue/composition-api'
import useInputOption from '../../useInputOption'
import useDeleteOption from '../../useDeleteOption'

export default defineComponent({
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BInputGroup,
    BFormCheckbox,
  },
  setup() {
    const { turnOnModal: turnOnDeleteOptionModal } = useDeleteOption()

    return {
      turnOnDeleteOptionModal,
      ...useInputOption(),
    }
  },
})
</script>
