import {
  computed,
} from '@vue/composition-api'
import loadash from 'lodash'
import data from './data'
import actions from './actions'
import useInputOption from './useInputOption'

export default () => {
  const fields = [
    'idx', 'thumb', 'product_no', 'name', 'state',
  ]

  const turnOnSidebar = () => {
    data.copyProduct.visible.value = true
  }
  const turnOffSidebar = () => {
    data.copyProduct.visible.value = false
  }

  const isValid = computed(() => {
    const tableRef = data.copyProduct.tableRef.value
    if (tableRef == null) return false
    const selectedItem = tableRef.items.find((item, i) => tableRef.selectedRows[i])
    return selectedItem != null
  })

  const searchSimpleProductList = () => {
    data.copyProduct.isSearching.value = true
    actions.searchSimpleProductList({
      search_text: data.copyProduct.searchText.value,
    }).then(response => {
      data.copyProduct.productList.value = response.data.data
      data.copyProduct.isSearching.value = false
    })
  }

  const copyProduct = () => {
    data.copyProduct.isSaving.value = true
    const tableRef = data.copyProduct.tableRef.value
    if (tableRef == null) return
    const selectedItem = tableRef.items.find((item, i) => tableRef.selectedRows[i])
    actions.fetchProductDetail({
      product_idx: selectedItem.idx,
    }).then(response => {
      const resData = response.data.data
      const resProduct = resData.product

      data.product.name.value = resProduct.name
      data.product.brandName.value = resProduct.brand_name
      data.product.categoryIdx.value = resProduct.category_idx
      data.product.stock.value = resProduct.stock
      data.product.sellType.value = resProduct.sell_type
      data.product.state.value = resProduct.state
      data.product.startAt.value = resProduct.start_at
      data.product.endAt.value = resProduct.end_at
      data.product.detailHtmlContent.value = resProduct.detail_html_content
      data.product.description.value = resProduct.description
      data.product.supplyPrice.value = resProduct.supply_price
      data.product.price.value = resProduct.price
      data.product.marketPrice.value = resProduct.market_price
      data.product.tagbyFeeRate.value = resProduct.tagby_fee_rate
      data.product.shippingFee.value = resProduct.shipping_fee
      data.product.maxBundleCount.value = resProduct.max_bundle_count
      data.product.deliveryDays.value = [resProduct.min_delivery_days, resProduct.max_delivery_days]
      data.product.deliveryContent.value = resProduct.delivery_content
      data.product.refundContent.value = resProduct.refund_content
      data.product.isAbroad.value = resProduct.is_abroad
      data.product.additionalShippingFeeCond.value = resProduct.additional_shipping_fee_cond
      data.product.shippingFreeCond.value = resProduct.shipping_free_cond
      data.product.isTaxFree.value = resProduct.vat_rate === 0
      data.product.abroadJson.value = resProduct.abroad_json

      data.shippingPolicy.shippingPolicyObj.value = resData.shipping_policy
      data.thumbImg.thumbImgFile.value = { url: resProduct.thumbnail_img_url }
      data.vendor.vendorObj.value = resData.vendor
      data.copyProduct.isSaving.value = false

      data.option.optionsMeta.value = resData.options_meta
      data.option.optionType.value = resData.options_meta.type
      data.option.optionCategories.value = resData.options_meta.categories
      data.option.optionNames.value = resData.options_meta.names?.map(nameArray => nameArray.join(','))
      // data.option.options.value = resData.options.map(option => ({ ...option, state: 'ACTIVE' }))
      const tempOptions = resData.options.map(option => ({ ...option, state: 'ACTIVE' }))

      const {
        makeSingleOptions,
        makeCombiOptions,
        getSyncedOptions,
      } = useInputOption()

      if (resData.options_meta.type === 'NO') {
        data.option.newOptions.value = []
      } else if (resData.options_meta.type === 'SINGLE') {
        data.option.newOptions.value = makeSingleOptions()
      } else if (resData.options_meta.type === 'COMBI') {
        data.option.newOptions.value = makeCombiOptions()
      }

      const generatedOptions = getSyncedOptions()
      data.option.options.value = generatedOptions.map(opt => {
        const sameOption = tempOptions.find(topt => loadash.isEqual(opt.options, topt.options))
        if (sameOption != null) {
          return {
            ...sameOption,
            idx: opt.idx,
          }
        }
        return {
          ...opt,
          state: 'INACTIVE',
        }
      })

      turnOffSidebar()
    })
  }

  return {
    ...data.copyProduct,
    fields,
    isValid,
    turnOnSidebar,
    turnOffSidebar,
    searchSimpleProductList,
    copyProduct,
  }
}
