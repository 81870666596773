<template>
  <BCard title="옵션 입력">
    <BRow>
      <BCol>
        <BFormRadioGroup
          v-model="optionType"
          :options="optionTypeList"
        />
      </BCol>
    </BRow>
    <BRow class="mt-1">
      <BCol>
        <OptionMetaGroup v-if="optionType !== 'NO'" />
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BButton
          variant="outline-primary"
          @click="updateOptionsMeta"
        >
          적용
        </BButton>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormRadioGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import useInputOption from '../../useInputOption'
import OptionMetaGroup from './OptionMetaGroup.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormInput,
    BButton,

    OptionMetaGroup,
  },
  setup() {
    const {
      optionTypeList,
      optionType,
      updateOptionsMeta,
    } = useInputOption()

    return {
      optionTypeList,
      optionType,
      updateOptionsMeta,
    }
  },
})
</script>
