import {
  computed,
} from '@vue/composition-api'
import data from './data'

export default () => {
  const computedTagbyFee = computed(() => {
    const {
      price,
      tagbyFeeRate,
      shippingFee,
    } = data.product
    const isValid = value => value != null && value !== ''
    if (![price.value, tagbyFeeRate.value, shippingFee.value].reduce((p, c) => p && isValid(c), true)) return NaN
    return Math.round((price.value + shippingFee.value) * (tagbyFeeRate.value + 0.03))
  })

  const computedInfFee = computed(() => {
    const {
      price,
      supplyPrice,
    } = data.product
    const isValid = value => value != null && value !== ''
    if (![price.value, supplyPrice.value].reduce((p, c) => p && isValid(c), true)) return NaN
    return Math.round((price.value - supplyPrice.value - computedTagbyFee.value) * (10 / 11))
  })

  const computedEtcIncome = computed(() => {
    const {
      price,
      supplyPrice,
    } = data.product
    return (price.value - supplyPrice.value - computedTagbyFee.value - computedInfFee.value)
  })

  return {
    computedTagbyFee,
    computedInfFee,
    computedEtcIncome,
    ...data.product,
  }
}
