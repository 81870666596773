<template>
  <div>
    <OptionMeta
      v-for="(category, i) in optionCategories"
      :key="i"
      :index="i"
      :category="category"
      :names="optionNames[i]"
      :visibleMinus="getVisibleMinus()"
      :visiblePlus="getVisiblePlus(i)"
      @inputCategory="inputCategory($event.value, $event.index)"
      @inputNames="inputNames($event.value, $event.index)"
      @deleteOptionMeta="deleteOptionMeta($event.index)"
      @appendOptionMeta="appendOptionMeta"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import OptionMeta from '../../components/OptionMeta.vue'
import useInputOption from '../../useInputOption'

export default defineComponent({
  components: {
    OptionMeta,
  },
  setup() {
    return {
      ...useInputOption(),
    }
  },
})
</script>
