import {
  computed,
} from '@vue/composition-api'
import data from './data'

export default () => {
  const optionTypeList = [
    { text: '없음', value: 'NO' },
    { text: '단독형', value: 'SINGLE' },
    { text: '조합형', value: 'COMBI' },
  ]
  const getVisibleMinus = () => data.option.optionCategories.value.length > 1
  const getVisiblePlus = i => data.option.optionCategories.value.length - 1 <= i
  const appendOptionMeta = () => {
    data.option.optionCategories.value = data.option.optionCategories.value.concat([null])
    data.option.optionNames.value = data.option.optionNames.value.concat([null])
  }
  const deleteOptionMeta = i => {
    data.option.optionCategories.value = data.option.optionCategories.value.slice(0, i).concat(data.option.optionCategories.value.slice(i + 1))
    data.option.optionNames.value = data.option.optionNames.value.slice(0, i).concat(data.option.optionNames.value.slice(i + 1))
  }
  const inputCategory = (value, index) => {
    data.option.optionCategories.value = data.option.optionCategories.value.map((v, i) => {
      if (i === index) {
        return value
      }
      return v
    })
  }
  const inputNames = (value, index) => {
    data.option.optionNames.value = data.option.optionNames.value.map((v, i) => {
      if (i === index) {
        return value
      }
      return v
    })
  }
  const makeSingleOptions = () => {
    let index = 0
    return data.option.optionNames.value
      .map(namesWithComma => namesWithComma.split(','))
      .map((nameArray, i) => {
        const category = data.option.optionCategories.value[i]
        return nameArray.map(name => {
          const settingIdx = index + 1
          index += 1
          return {
            idx: settingIdx,
            category,
            name,
            parent_idx: null,
          }
        })
      })
      .reduce((p, c) => p.concat(c), [])
  }
  const makeCombiOptions = () => {
    let index = 0
    const namesList = data.option.optionNames.value.map(namesWithComma => namesWithComma.split(','))

    const makeCombiOptionsRecursion = (i, parentIdx, name) => {
      const category = data.option.optionCategories.value[i]
      const nextIndex = index + 1
      index += 1
      if (i === namesList.length - 1) {
        return [{
          idx: index,
          category,
          name,
          parent_idx: parentIdx,
        }]
      }
      const nextNames = namesList[i + 1]
      return [{
        idx: index,
        category,
        name,
        parent_idx: parentIdx,
      }].concat(nextNames.map(n => makeCombiOptionsRecursion(i + 1, nextIndex, n)))
        .reduce((p, c) => p.concat(c), [])
    }

    return namesList[0].map(name => makeCombiOptionsRecursion(0, null, name))
      .reduce((p, c) => p.concat(c), [])
  }

  const getSyncedOptions = () => {
    const refCount = data.option.newOptions.value.reduce((previous, option) => {
      const refCountOfParentIdx = previous[option.parent_idx] ?? 0
      return {
        ...previous,
        [option.parent_idx]: refCountOfParentIdx + 1,
      }
    }, {})
    const optionsByIdx = data.option.newOptions.value.reduce((previous, option) => ({
      ...previous,
      [option.idx]: option,
    }), {})
    const getOptionCategoryRecursion = idx => {
      const option = optionsByIdx[idx]
      if (option.parent_idx == null) {
        return [{
          category: option.category,
          name: option.name,
        }]
      }
      return [
        ...getOptionCategoryRecursion(option.parent_idx),
        {
          category: option.category,
          name: option.name,
        },
      ]
    }
    const leafOptions = data.option.newOptions.value
      .filter(option => (refCount[option.idx] ?? 0) === 0)
      .map(option => ({
        options: getOptionCategoryRecursion(option.idx),
        add_price: 0,
        add_supply_price: 0,
        add_tagby_fee: 0,
        stock: 0,
        idx: option.idx,
        state: 'ACTIVE',
        _next_idx: option.parent_idx,
      }))
    return leafOptions
  }

  const updateOptionsMeta = () => {
    data.option.optionsMeta.value = {
      type: data.option.optionType.value,
      categories: data.option.optionCategories.value,
      names: data.option.optionNames.value,
    }

    if (data.option.optionType.value === 'NO') {
      data.option.options.value = []
      data.option.newOptions.value = []
    }
    if (data.option.optionType.value === 'SINGLE') {
      data.option.newOptions.value = makeSingleOptions()
    }
    if (data.option.optionType.value === 'COMBI') {
      data.option.newOptions.value = makeCombiOptions()
    }
    data.option.options.value = getSyncedOptions()
  }

  const optionsWithKey = computed(() => data.option.options.value.map(o => {
    const optionKeyValues = o.options.reduce((p, c) => ({
      ...p,
      [c.category]: c.name,
    }), {})
    return {
      ...o,
      ...optionKeyValues,
    }
  }))
  const activeOptions = computed(() => optionsWithKey.value.filter(o => o.state === 'ACTIVE'))
  const inputOption = (idx, key, value) => {
    data.option.options.value = data.option.options.value.map(option => {
      if (option.idx === idx) {
        return {
          ...option,
          [key]: parseInt(value, 10),
        }
      }
      return option
    })
  }

  const optionFields = computed(() => [
    { key: 'selected', label: '' },
    ...data.option.optionsMeta.value?.categories ?? [],
    { key: 'add_supply_price', label: '추가공급가' },
    { key: 'add_price', label: '추가가격' },
    { key: 'add_tagby_fee', label: '추가태그바이수수료' },
    { key: 'stock', label: '재고수량' },
  ])

  const toggleTableChecked = row => {
    const i = data.option.optionsTableRef.value.items.findIndex(item => item.idx === row.item.idx)
    if (row.rowSelected) {
      data.option.optionsTableRef.value.selectRow(i)
    } else {
      data.option.optionsTableRef.value.unselectRow(i)
    }
  }

  return {
    optionTypeList,
    getVisibleMinus,
    getVisiblePlus,
    appendOptionMeta,
    deleteOptionMeta,
    inputCategory,
    inputNames,
    updateOptionsMeta,

    makeSingleOptions,
    makeCombiOptions,
    getSyncedOptions,

    optionsWithKey,
    activeOptions,
    inputOption,
    optionFields,
    toggleTableChecked,

    ...data.option,
  }
}
