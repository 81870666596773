import {
  computed,
} from '@vue/composition-api'
import data from './data'
import actions from './actions'

export default () => {
  const hasVendor = computed(() => data.vendor.vendorObj.value != null)

  const shippingPolicyFields = [
    { key: 'name', label: '그룹명' },
    { key: 'type', label: '계산방식' },
    { key: 'jeju', label: '제주' },
    { key: 'island_mountain', label: '도서산간' },
  ]

  const isValidChangeShippingPolicy = computed(() => {
    const tableRef = data.shippingPolicy.shippingPolicyRef.value
    if (tableRef == null) return false
    const selectedItem = tableRef.items.find((item, i) => tableRef.selectedRows[i])
    return selectedItem != null
  })

  const fetchShippingPolicyList = () => {
    data.shippingPolicy.isFetchingShippingPolicyList.value = true
    actions.fetchShippingPolicyList({
      vendor_idx: data.vendor.vendorObj.value.idx,
    }).then(response => {
      data.shippingPolicy.shippingPolicyList.value = response.data.data
      data.shippingPolicy.isFetchingShippingPolicyList.value = false
    })
  }

  const turnOnChangeShippingPolicySidebar = () => {
    data.shippingPolicy.visibleChangeShippingPolicySidebar.value = true
    if (hasVendor.value) {
      fetchShippingPolicyList()
    }
  }
  const turnOffChangeShippingPolicySidebar = () => {
    data.shippingPolicy.visibleChangeShippingPolicySidebar.value = false
  }

  const changeShippingPolicy = () => {
    const tableRef = data.shippingPolicy.shippingPolicyRef.value
    if (tableRef == null) return
    const selectedItem = tableRef.items.find((item, i) => tableRef.selectedRows[i])
    data.shippingPolicy.shippingPolicyObj.value = selectedItem
    data.product.additionalShippingFeeCond.value = selectedItem.additional_shipping_fee_cond
    turnOffChangeShippingPolicySidebar()
  }

  const turnOnRemoveShippingPolicyModal = () => {
    data.shippingPolicy.visibleRemoveShippingPolicyModal.value = true
  }

  const turnOffRemoveShippingPolicyModal = () => {
    data.shippingPolicy.visibleRemoveShippingPolicyModal.value = false
  }

  const removeShippingPolicy = () => {
    data.shippingPolicy.shippingPolicyObj.value = null
    data.product.additionalShippingFeeCond.value = {
      jeju: 0,
      island_mountain: 0,
    }
    turnOffRemoveShippingPolicyModal()
  }

  return {
    ...data.shippingPolicy,
    hasVendor,
    shippingPolicyFields,
    isValidChangeShippingPolicy,
    fetchShippingPolicyList,
    turnOnChangeShippingPolicySidebar,
    turnOffChangeShippingPolicySidebar,
    changeShippingPolicy,

    turnOnRemoveShippingPolicyModal,
    turnOffRemoveShippingPolicyModal,
    removeShippingPolicy,
  }
}
