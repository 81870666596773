<template>
  <TagbyActionButton
    variant="outline-secondary"
    text="벤더 변경"
    @click="turnOnChangeVendorSidebar"
  />

</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import useVendorInfo from '../../useVendorInfo'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    return {
      ...useVendorInfo(),
    }
  },
})
</script>
