import {
  computed,
} from '@vue/composition-api'
import data from './data'
import actions from './actions'
import useToast from '@/utils/toast'

export default () => {
  const { makeToast } = useToast()

  const sellTypeOptions = [
    { label: '상시판매', value: 'ALWAYS' },
    { label: '모집판매', value: 'RECRUITMENT' },
  ]
  const stateOptions = [
    { label: '판매중', value: 'ACTIVE' },
    { label: '승인대기중', value: 'CONFIRMING' },
    { label: '매진', value: 'SOLDOUT' },
    { label: '숨김', value: 'HIDDEN' },
    { label: '삭제', value: 'INACTIVE' },
    { label: '삭제요청중', value: 'DELETING' },
    { label: '작성중', value: 'WRITING' },
  ]

  const hasOptions = computed(() => data.option.options.value.length > 0)
  const computedOptionStock = computed(() => data.option.options.value.reduce((p, c) => p + c.stock, 0))

  const fetchCategories = () => {
    actions.fetchCategories().then(response => {
      data.product.categoryOptions.value = response.data.data.map(c => ({ label: `${c.class_1} > ${c.class_2}`, value: c.idx }))
    }).catch(() => {
      makeToast('danger', '카테고리 목록을 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }

  return {
    sellTypeOptions,
    stateOptions,
    hasOptions,
    computedOptionStock,
    ...data.product,
    fetchCategories,
  }
}
