<template>
  <TagbySidebarWithButton
    title="합배송그룹 변경"
    :visible="visibleChangeShippingPolicySidebar"
    :isSaving="isSavingChangeShippingPolicy"
    :isValid="isValidChangeShippingPolicy"
    @ok="changeShippingPolicy"
    @hidden="turnOffChangeShippingPolicySidebar"
    @cancel="turnOffChangeShippingPolicySidebar"
  >
    <BRow
      v-if="!hasVendor"
      class="mb-1"
    >
      <BCol>
        <div
          class="text-danger"
        >
          벤더가 지정되지 않아서 합배송 그룹을 가져올 수 없습니다
        </div>
      </BCol>
    </BRow>
    <BRow v-else>
      <BTable
        ref="shippingPolicyRef"
        :fields="shippingPolicyFields"
        :items="shippingPolicyList"
        :busy="isFetchingShippingPolicyList"
        small
        show-empty
        selectable
        select-mode="single"
      >
        <template #cell(jeju)="data">
          {{ data.item.additional_shipping_fee_cond.jeju }}
        </template>

        <template #cell(island_mountain)="data">
          {{ data.item.additional_shipping_fee_cond.island_mountain }}
        </template>
      </BTable>
    </BRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import useShippingPolicy from '../../useShippingPolicy'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    BRow,
    BCol,
    BTable,
  },
  setup() {
    return {
      ...useShippingPolicy(),
    }
  },
})
</script>
