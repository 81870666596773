<template>
  <div>
    <DeleteOptionModal />
    <ChangeShippingPolicySidebar />
    <ChangeVendorSidebar />
    <RemoveShippingPolicyModal />
    <CopyProductSidebar />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import DeleteOptionModal from './DeleteOptionModal.vue'
import ChangeShippingPolicySidebar from './ChangeShippingPolicySidebar.vue'
import ChangeVendorSidebar from './ChangeVendorSidebar.vue'
import RemoveShippingPolicyModal from './RemoveShippingPolicyModal.vue'
import CopyProductSidebar from './CopyProductSidebar.vue'

export default defineComponent({
  components: {
    DeleteOptionModal,
    ChangeShippingPolicySidebar,
    ChangeVendorSidebar,
    RemoveShippingPolicyModal,
    CopyProductSidebar,
  },
  setup() {

  },
})
</script>
