<template>
  <TagbyDangerModal
    :visible="visible"
    title="옵션 삭제"
    content="옵션을 제거하시겠습니까?"
    @ok="deleteOption"
    @hidden="turnOffModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import useDeleteOption from '../../useDeleteOption'
import TagbyDangerModal from '@/components/TagbyDangerModal.vue'

export default defineComponent({
  components: {
    TagbyDangerModal,
  },
  setup() {
    return {
      ...useDeleteOption(),
    }
  },
})
</script>
