import {
  computed,
} from '@vue/composition-api'
import router from '@/router'
import data from './data'
import actions from './actions'
import useResolveValue from '@/utils/resolveValue'
import useToast from '@/utils/toast'

export default () => {
  // const isValid = computed(() => {
  //   const
  // })
  const {
    resolveUtcDateTime,
  } = useResolveValue()

  const {
    makeToast,
  } = useToast()

  const validateParams = () => {
    if (!data.product.name.value) {
      makeToast('danger', '상품명은 필수값입니다')
      return false
    }
    if (!data.product.brandName.value) {
      makeToast('danger', '브랜드명은 필수값입니다')
      return false
    }
    if (!data.product.categoryIdx.value) {
      makeToast('danger', '카테고리는 필수값입니다')
      return false
    }
    if (!data.product.sellType.value) {
      makeToast('danger', '판매타입은 필수값입니다')
      return false
    }
    if (!data.product.state.value) {
      makeToast('danger', '판매상태는 필수값입니다')
      return false
    }
    if (data.product.stock.value !== 0 && !data.product.stock.value && data.option.options.value.length === 0) {
      makeToast('danger', '재고는 필수값입니다')
      return false
    }
    if (!data.thumbImg.thumbImgFile.value?.url) {
      makeToast('danger', '썸네일은 필수값입니다')
      return false
    }
    if (!data.product.detailHtmlContent.value && !data.product.description.value) {
      makeToast('danger', '상세설명은 필수값입니다')
      return false
    }
    if (!data.product.supplyPrice.value) {
      makeToast('danger', '공급가는 필수값입니다')
      return false
    }
    if (!data.product.price.value) {
      makeToast('danger', '가격은 필수값입니다')
      return false
    }
    if (!data.product.marketPrice.value) {
      makeToast('danger', '권장소비자가는 필수값입니다')
      return false
    }
    if (!data.product.tagbyFeeRate.value) {
      makeToast('danger', '태그바이수수료율은 필수값입니다')
      return false
    }
    if (data.product.shippingFee.value !== 0 && !data.product.shippingFee.value) {
      makeToast('danger', '배송비는 필수값입니다')
      return false
    }
    if (!data.product.maxBundleCount.value) {
      makeToast('danger', '최대묶음개수는 필수값입니다')
      return false
    }
    if (!data.product.deliveryDays.value[0]) {
      makeToast('danger', '배송일은 필수값입니다')
      return false
    }
    if (!data.product.deliveryDays.value[1]) {
      makeToast('danger', '배송일은 필수값입니다')
      return false
    }
    if (!data.product.deliveryContent.value) {
      makeToast('danger', '배송상세정보는 필수값입니다')
      return false
    }
    if (!data.product.refundContent.value) {
      makeToast('danger', '환불상세정보는 필수값입니다')
      return false
    }
    if (!data.vendor.vendorObj.value?.idx) {
      makeToast('danger', '벤더는 필수값입니다')
      return false
    }
    return true
  }

  const createProduct = () => {
    if (!validateParams()) {
      return
    }
    data.product.isSaving.value = true
    actions.createProduct({
      product: {
        name: data.product.name.value,
        brand_name: data.product.brandName.value,
        category_idx: data.product.categoryIdx.value,
        stock: data.product.stock.value,
        sell_type: data.product.sellType.value,
        state: data.product.state.value,
        product_no: data.product.productNo.value,
        start_at: resolveUtcDateTime(data.product.startAt.value),
        end_at: resolveUtcDateTime(data.product.endAt.value),
        detail_html_content: data.product.detailHtmlContent.value,
        description: data.product.description.value,
        supply_price: data.product.supplyPrice.value,
        price: data.product.price.value,
        market_price: data.product.marketPrice.value,
        tagby_fee_rate: data.product.tagbyFeeRate.value,
        shipping_fee: data.product.shippingFee.value,
        max_bundle_count: data.product.maxBundleCount.value,
        min_delivery_days: data.product.deliveryDays.value[0],
        max_delivery_days: data.product.deliveryDays.value[1],
        delivery_content: data.product.deliveryContent.value,
        refund_content: data.product.refundContent.value,
        is_abroad: data.product.isAbroad.value,
        additional_shipping_fee_cond: data.product.additionalShippingFeeCond.value,
        shipping_free_cond: data.product.shippingFreeCond.value,
        is_tax_free: data.product.isTaxFree.value,
        abroad_json: data.product.abroadJson.value,
        thumbnail_img_url: data.thumbImg.thumbImgFile.value.url,
        shipping_policy_idx: data.shippingPolicy.shippingPolicyObj.value?.idx,
        vendor_idx: data.vendor.vendorObj.value?.idx,
      },
      options: data.option.options.value,
      new_options: data.option.newOptions.value,
    }).then(response => {
      router.replace({ name: 'commerce-product-detail', params: { idx: response.data.data.idx } })
    }).catch(() => {
      makeToast('danger', '상품 생성에 실패 했습니다')
    }).finally(() => {
      data.product.isSaving.value = false
    })
  }

  return {
    isSaving: data.product.isSaving,
    createProduct,
  }
}
