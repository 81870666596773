import axiosFrame from '@/utils/axiosFrame'

export default {
  fetchCopiedProduct: axiosFrame('get', '/manage/product/copied-product/'),
  fetchProductDetail: axiosFrame('get', '/manage/product-re/detail/'),
  searchSimpleProductList: axiosFrame('post', '/manage/product-re/search-simple-product-list/'),
  fetchCategories: axiosFrame('get', '/manage/product/categories/'),
  fetchVendorList: axiosFrame('get', '/manage/product/vendors/'),
  fetchShippingPolicyList: axiosFrame('get', '/manage/product-re/shipping-policy-list/'),
  createProduct: axiosFrame('post', '/manage/product/create-product/'),
}
