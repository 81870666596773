<template>
  <div>
    <BButton
      :id="id"
      variant="outline-primary"
      :href="href"
      :download="download"
    >
      <feather-icon icon="ImageIcon" />
    </BButton>
    <BPopover
      :target="id"
      triggers="hover"
      :placement="placement"
      no-fade
    >
      <BImg
        :src="url"
        fluid
      />
    </BPopover>
  </div>
</template>

<script>
import {
  BButton,
  BPopover,
  BImg,
} from 'bootstrap-vue'
import { v4 as uuid4 } from 'uuid'

export default {
  components: {
    BButton,
    BPopover,
    BImg,
  },
  props: {
    url: {
      type: String,
      default: null,
    },
    download: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'right',
    },
  },
  data() {
    return {
      id: uuid4(),
    }
  },
  computed: {
    href() {
      return this.download ? this.url : null
    },
  },
}
</script>
