import {
  computed,
} from '@vue/composition-api'
import data from './data'
import actions from './actions'

export default () => {
  const vendorFields = [
    'idx',
    'name',
    'email',
    'company_name',
  ]

  const fetchVendorList = () => {
    data.vendor.isFetchingVendorList.value = true
    actions.fetchVendorList({
      text: data.vendor.searchText.value,
    }).then(response => {
      data.vendor.vendorList.value = response.data.data
      data.vendor.isFetchingVendorList.value = false
    })
  }

  const turnOnChangeVendorSidebar = () => {
    data.vendor.visibleChangeVendorSidebar.value = true
  }

  const turnOffChangeVendorSidebar = () => {
    data.vendor.visibleChangeVendorSidebar.value = false
  }

  const isValidChangeVendor = computed(() => {
    const tableRef = data.vendor.vendorTableRef.value
    if (tableRef == null) return false
    const selectedItem = tableRef.items.find((item, i) => tableRef.selectedRows[i])
    return selectedItem != null
  })

  const changeVendor = () => {
    if (!isValidChangeVendor.value) return
    const tableRef = data.vendor.vendorTableRef.value
    const selectedItem = tableRef.items.find((item, i) => tableRef.selectedRows[i])
    data.vendor.vendorObj.value = selectedItem
    turnOffChangeVendorSidebar()
  }
  return {
    ...data.vendor,
    vendorFields,
    fetchVendorList,
    turnOnChangeVendorSidebar,
    turnOffChangeVendorSidebar,
    isValidChangeVendor,
    changeVendor,
  }
}
