<template>
  <BFormInput
    :value="decimalToPercent(value)"
    number
    @input="$emit('input', percentToDecimal($event))"
  />
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  methods: {
    decimalToPercent(value) {
      return value != null && value !== '' ? (value * 100).toFixed(2) : null
    },
    percentToDecimal(value) {
      return value != null && value !== '' ? value / 100 : null
    },
  },
}
</script>
