<template>
  <TagbySidebarWithButton
    title="상품 복사"
    width="40%"
    :visible="visible"
    :isSaving="isSaving"
    :isValid="isValid"
    @ok="copyProduct"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <BRow>
      <BCol>
        <BFormGroup>
          <BInputGroup>
            <BFormInput
              v-model="searchText"
              @keydown.enter="searchSimpleProductList"
            />
            <BInputGroupAppend>
              <BButton
                variant="outline-primary"
                @click="searchSimpleProductList"
              >
                검색
              </BButton>
            </BInputGroupAppend>
          </BInputGroup>
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BTable
        ref="tableRef"
        :fields="fields"
        :items="productList"
        :busy="isSearching"
        small
        responsive
        show-empty
        selectable
        select-mode="single"
      >
        <template #cell(thumb)="data">
          <BImg
            :src="data.item.thumbnail_img_url"
            width="60"
            height="60"
            rounded
          />
        </template>

        <template #cell(product_no)="data">
          <div>
            <div>
              {{ data.item.product_no }}
            </div>
            <div v-if="data.item.tagby_product_no">
              ({{ data.item.tagby_product_no }})
            </div>
          </div>
        </template>
      </BTable>
    </BRow>
  </TagbySidebarWithButton>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import useCopyProduct from '../../useCopyProduct'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BImg,
  },
  setup() {
    return {
      ...useCopyProduct(),
    }
  },
})
</script>
