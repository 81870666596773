<template>
  <BRow class="mb-1">
    <BCol cols="3">
      <BFormInput
        :value="category"
        placeholder="예시: 컬러"
        @input="$emit('inputCategory', {value: $event, index})"
      />
    </BCol>
    <BCol cols="4">
      <BFormInput
        :value="names"
        placeholder="예시: 빨강,노랑(,로 구분)"
        @input="$emit('inputNames', {value: $event, index})"
      />
    </BCol>
    <BCol cols="2">
      <BButton
        v-if="visibleMinus"
        variant="danger"
        class="btn-icon mr-1"
        @click="$emit('deleteOptionMeta', {index})"
      >
        <FeatherIcon icon="MinusIcon" />
      </BButton>
      <BButton
        v-if="visiblePlus"
        variant="primary"
        class="btn-icon"
        @click="$emit('appendOptionMeta')"
      >
        <FeatherIcon icon="PlusIcon" />
      </BButton>
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    category: {
      type: String,
      default: '',
    },
    names: {
      type: String,
      default: '',
    },
    visibleMinus: {
      type: Boolean,
      default: false,
    },
    visiblePlus: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
  },
})
</script>
