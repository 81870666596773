<template>
  <TagbyActionButton
    variant="outline-info"
    text="상품정보 가져오기"
    @click="turnOnSidebar"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import useCopyProduct from '../../useCopyProduct'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    return {
      ...useCopyProduct(),
    }
  },
})
</script>
