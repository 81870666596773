import {
  ref,
} from '@vue/composition-api'

export default {
  vendor: {
    vendorTableRef: ref(),
    vendorObj: ref(),
    searchText: ref(),
    vendorList: ref(),
    visibleChangeVendorSidebar: ref(false),
    isFetchingVendorList: ref(false),
    isSavingChangeVendor: ref(false),
  },
  thumbImg: {
    thumbImgRef: ref(),
    thumbImgFile: ref({
      url: null,
    }),
    isSavingImgFile: ref(false),
  },
  shippingPolicy: {
    shippingPolicyRef: ref(),
    shippingPolicyObj: ref(),
    visibleChangeShippingPolicySidebar: ref(false),
    isSavingChangeShippingPolicy: ref(false),
    shippingPolicyList: ref(),
    isFetchingShippingPolicyList: ref(false),
    visibleRemoveShippingPolicyModal: ref(false),
  },
  product: {
    name: ref(),
    brandName: ref(),
    categoryIdx: ref(),
    productNo: ref(),
    stock: ref(),
    sellType: ref(),
    state: ref(),
    startAt: ref(),
    endAt: ref(),
    detailHtmlContent: ref(),
    description: ref(),
    supplyPrice: ref(),
    price: ref(),
    marketPrice: ref(),
    tagbyFeeRate: ref(),
    shippingFee: ref(),
    maxBundleCount: ref(),
    deliveryDays: ref([]),
    deliveryContent: ref(),
    refundContent: ref(),
    isAbroad: ref(false),
    additionalShippingFeeCond: ref({
      jeju: 0,
      island_mountain: 0,
    }),
    shippingFreeCond: ref({
      gte: null,
    }),
    isTaxFree: ref(false),
    abroadJson: ref({
      nation: null,
      acceptor: null,
      contact: null,
    }),
    categoryOptions: ref([]),
    hasLoadError: ref(false),
    isUploadingFile: ref(false),
    isSaving: ref(false),
  },
  copyProduct: {
    visible: ref(false),
    searchText: ref(),
    productList: ref(),
    isSaving: ref(false),
    isSearching: ref(false),
    tableRef: ref(),
  },
  option: {
    optionsTableRef: ref(),
    options: ref([]),
    optionsMeta: ref(),
    optionType: ref('NO'),
    optionCategories: ref([null]),
    optionNames: ref([null]),
    newOptions: ref([]),
    visibleDeleteOptionModal: ref(false),
  },
}
