<template>
  <TagbyActionButton
    variant="outline-secondary"
    text="배송정책 제거"
    @click="turnOnRemoveShippingPolicyModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import useShippingPolicy from '../../useShippingPolicy'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    return {
      ...useShippingPolicy(),
    }
  },
})
</script>
