<template>
  <TagbyPrimaryModal
    title="배송정책 제거"
    content="현재 배송정책을 사용하지 않겠습니까?"
    :visible="visibleRemoveShippingPolicyModal"
    @ok="removeShippingPolicy"
    @hidden="turnOffRemoveShippingPolicyModal"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import useShippingPolicy from '../../useShippingPolicy'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    return {
      ...useShippingPolicy(),
    }
  },
})
</script>
