import data from './data'

export default () => {
  const turnOnModal = () => {
    data.option.visibleDeleteOptionModal.value = true
  }
  const turnOffModal = () => {
    data.option.visibleDeleteOptionModal.value = false
  }
  const deleteOption = () => {
    const { items, selectedRows } = data.option.optionsTableRef.value
    const selectedOptionIdxList = items.filter((v, i) => selectedRows[i]).map(r => r.idx)
    data.option.options.value = data.option.options.value.map(o => {
      if (selectedOptionIdxList.includes(o.idx)) {
        return {
          ...o,
          state: 'INACTIVE',
        }
      }
      return {
        ...o,
      }
    })
  }

  return {
    visible: data.option.visibleDeleteOptionModal,
    turnOnModal,
    turnOffModal,
    deleteOption,
  }
}
