<template>
  <TagbyActionCard>
    <template #left>
      <CreateButton />
      <ChangeVendorButton />
      <CopyProductButton />
    </template>
    <template #right>
      <ChangeShippingPolicyButton />
      <RemoveShippingPolicyButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
import CreateButton from './CreateButton.vue'
import ChangeShippingPolicyButton from './ChangeShippingPolicyButton.vue'
import ChangeVendorButton from './ChangeVendorButton.vue'
import RemoveShippingPolicyButton from './RemoveShippingPolicyButton.vue'
import CopyProductButton from './CopyProductButton.vue'

export default defineComponent({
  components: {
    TagbyActionCard,
    CreateButton,
    ChangeShippingPolicyButton,
    ChangeVendorButton,
    RemoveShippingPolicyButton,
    CopyProductButton,
  },
  setup() {

  },
})
</script>
